import React, { useEffect } from "react";
import { navigate } from "gatsby";
import Layout from "../components/Layouts";
import Seo from "../components/SEO";

const Index = () => {
  useEffect(() => {
    navigate("/login");
  }, []);
  return (
    <Layout>
      <Seo title="Betterresto" />
    </Layout>
  );
};

export default Index;
